import { Link, useLocation } from "react-router-dom";
import axios from 'axios';
import Popup from 'reactjs-popup';
import { useState, useEffect } from 'react';
function ResultPage (){
    const [students, setStudents] = useState([]);    
    const location = useLocation(); // Get the current location
    const student_id = location.pathname.split("/")[2];
    
useEffect(() => {
  studentOnId();
}, [student_id]);  

  const studentOnId = async () => {
    try {
      const response = await axios.get(`/findbyid?student_id=${student_id}`);
      setStudents(response.data.row);
    } catch (error) {
      console.error(error);
      alert("An error occurred");
    }
  };

const [editingStudentId, setEditingStudentId] = useState(null);
  const [editedStudent, setEditedStudent] = useState({});

  const handleChange = (e, fieldName) => {
    setEditedStudent({ ...editedStudent, [fieldName]: e.target.value });
  };

  const handleSave = (studentId) => {
    updateStudent(studentId, editedStudent);
    setEditingStudentId(null);
  };

  const handleEdit = (studentId, student) => {
    setEditingStudentId(studentId);
    setEditedStudent(student);
  };
  
  const updateStudent = async (studentId, updatedStudent) => {
    try {
      await axios.put(`/students/${studentId}`, updatedStudent);
      //await axios.put(`/students/${studentId}`, updatedStudent);
      studentOnId();
    } catch (error) {
      console.log(error);
    }
  };
 //
 return <div>
            <div className="container">
        <nav className="navbar">
            <Link to={"/searchPage"}><button type="searchPage">Search</button></Link>
            <Popup trigger=
                        {<button type="logOut">Logout</button>} 
                        modal nested>
                        {
                        _close => ( 
                        <div className='modal'> 
                            <div type="logOutDescription"> 
                                Are you sure? 
                            </div>
                            <Link to={"/"}>
                                <button type="logOutConfirm">
                                    Logout
                                </button>
                            </Link>
                            <button type="logOutCancel" onClick={() => {_close()}}>
                                Cancel
                            </button>
                        </div>
                        )
                        }
                    </Popup>
        </nav>
        
        <form>  
        <tbody>
            {students.map(student => (
              <tr key={student.student_id}>
                <h1>{student.last_name} {student.first_name}</h1>
                <tr>
                  <td type = "header">Student ID:</td>
                  <td type = "data">{student.student_id}</td>
                </tr>
                {editingStudentId === student.student_id ? (
                <>
                <tr>
                  <td type="header">First Name:</td>
                  <td>
                    <input
                      defaultValue={student.first_name}
                      onChange={(e) => handleChange(e, "first_name")}
                    />
                  </td> 
                  <td>
                    <button onClick={() => handleSave(student.student_id)}>Save</button>
                    <button onClick={() => handleEdit(null)}>Cancel</button>
                  </td>
                </tr>  
                <tr>
                  <td type="header">Last Name:</td>
                  <td>
                    <input
                      defaultValue={student.last_name}
                      onChange={(e) => handleChange(e, "last_name")}
                    />
                  </td>
                  <td>
                    <button onClick={() => handleSave(student.student_id)}>Save</button>
                    <button onClick={() => handleEdit(null)}>Cancel</button>
                  </td>
                </tr>
                <tr>
                  <td type="header">Grad Year:</td> 
                  <td>
                    <input
                      defaultValue={student.grad_year}
                      onChange={(e) => handleChange(e, "grad_year")}
                    />
                  </td>
                  <td>
                    <button onClick={() => handleSave(student.student_id)}>Save</button>
                    <button onClick={() => handleEdit(null)}>Cancel</button>
                  </td>
                </tr>
                <tr>
                  <td type="header">Major Code:</td>
                  <td>
                    <input
                      defaultValue={student.major_code}
                      onChange={(e) => handleChange(e, "major_code")}
                    />
                  </td>
                  <td>
                    <button onClick={() => handleSave(student.student_id)}>Save</button>
                    <button onClick={() => handleEdit(null)}>Cancel</button>
                  </td>
                </tr>
                <tr>
                  <td type="header">Minor Code:</td>
                  <td>
                    <input
                      defaultValue={student.minor_code}
                      onChange={(e) => handleChange(e, "minor_code")}
                    />
                  </td>
                  <td>
                    <button onClick={() => handleSave(student.student_id)}>Save</button>
                    <button onClick={() => handleEdit(null)}>Cancel</button>
                  </td>
                </tr>
                <tr>
                  <td type="header">Notes:</td>
                  <td>
                    <input
                      defaultValue={student.Notes}
                      onChange={(e) => handleChange(e, "Notes")}
                    />
                  </td>
                  <td>
                    <button onClick={() => handleSave(student.student_id)}>Save</button>
                    <button onClick={() => handleEdit(null)}>Cancel</button>
                  </td>
                </tr>
                <tr>
                  <td type="header">Phone Number:</td>
                  <td>
                    <input
                      defaultValue={student.Phone_Number}
                      onChange={(e) => handleChange(e, "Phone_Number")}
                    />
                  </td>
                  <td>
                    <button onClick={() => handleSave(student.student_id)}>Save</button>
                    <button onClick={() => handleEdit(null)}>Cancel</button>
                  </td>     
                </tr>
                <tr>
                  <td type="header">Otterbein Email:</td>
                  <td>
                    <input
                      defaultValue={student.otterbein_email}
                      onChange={(e) => handleChange(e, "otterbein_email")}
                    />
                  </td>
                  <td>
                    <button onClick={() => handleSave(student.student_id)}>Save</button>
                    <button onClick={() => handleEdit(null)}>Cancel</button>
                  </td>
                </tr>
                </>
                ) : (
                <>
                <tr>
                  <td type="header">First Name:</td>
                  <td type="data">{student.first_name}</td>
                </tr>  
                <tr>
                  <td type="header">Last Name:</td>
                  <td type="data">{student.last_name}</td>
                </tr>
                <tr>
                  <td type="header">Grad Year:</td> 
                  <td type="data">{student.grad_year}</td>
                </tr>
                <tr>
                  <td type="header">Major Code:</td>
                  <td type="data">{student.major_code}</td>  
                </tr>
                <tr>
                  <td type="header">Minor Code:</td>
                  <td type="data">{student.minor_code}</td>
                </tr>
                <tr>
                  <td type="header">Notes:</td>
                  <td type="data">{student.Notes}</td>     
                </tr>
                <tr>
                  <td type="header">Phone Number:</td>
                  <td type="data">{student.Phone_Number}</td>    
                </tr>
                <tr>
                  <td type="header">Otterbein Email:</td>
                  <td type="data">{student.otterbein_email}</td>
                </tr>
                <tr>
                <td type="header">Edit Information:</td>
                <td>
                  <button onClick={() => handleEdit(student.student_id, student)}>
                    Edit
                  </button>  
                </td>
                </tr>
                </>
                )}
              </tr>
            ))}
          </tbody>
          
        </form>  
               
       
        </div>
    </div>
            
  }
  
export default ResultPage;