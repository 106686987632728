import { Link } from "react-router-dom";
import axios from 'axios';
import Popup from 'reactjs-popup';
import { useState } from 'react';

function CreateStudentPage() {

    const [newStudent, setNewStudent] = useState({
        student_id: "",
        prospect_id: null,
        first_name: "",
        last_name: "",
        otterbein_email: "",
        grad_year: null,
        Notes: "",
        Phone_Number: null,
        minor_code: null,
        major_code: "",
        ST_ADDR: "",
        CITY: "",
        ST_CD: "",
        ZIP_CD: "",
        CTRY_CD: "",
        Email: "",
        current_job: ""
    });

    const handleChange = (e, fieldName) => {
        setNewStudent({ ...newStudent, [fieldName]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check for required fields
        if (
            !newStudent.student_id ||
            !newStudent.first_name ||
            !newStudent.last_name ||
            !newStudent.grad_year ||
            !newStudent.otterbein_email
        ) {
            alert("Please fill in all required fields");
            return;
        }

        // Check for student id format
        const studentIdRegex = /^A\d{8}$/;
        if (
            newStudent.student_id &&
            !studentIdRegex.test(newStudent.student_id)
        ) {
            alert("Please enter a valid student ID format (A########)");
            return;
        }

        // Check for phone number format
        const phoneRegex = /^\d{3}\d{3}\d{4}$/;
        if (
            newStudent.Phone_Number &&
            !phoneRegex.test(newStudent.Phone_Number)
        ) {
            alert("Please enter a valid phone number format (##########)");
            return;
        }

        // Check for minor code format
        const minorRegex = /^[A-Za-z]{2}\d{2}$/;
        if (
            newStudent.minor_code &&
            !minorRegex.test(newStudent.minor_code)
        ) {
            alert("Please enter a valid minor code format (LL##)");
            return;
        }

        // Check for major code format
        const majorRegex = /^([A-Za-z]{2}\d{2})\s+(.+)$/;
        if (
            newStudent.major_code &&
            !majorRegex.test(newStudent.major_code)
        ) {
            alert("Please enter a valid major code format (LL## Major_Name)");
            return;
        }


        // Check for email format
        const emailRegex = /\S+@\S+\.\S+/;
        if (
            newStudent.Email &&
            !emailRegex.test(newStudent.Email)
        ) {
            alert("Please enter a valid email address");
            return;
        }

        // Check for mailing address fields
        if (
            !newStudent.ST_ADDR ||
            !newStudent.CITY ||
            !newStudent.ST_CD ||
            !newStudent.ZIP_CD ||
            !newStudent.CTRY_CD
        ) {
            alert("Please fill in all required mailing address fields");
            return;
        }

        try {
            await axios.post(`/students`, newStudent);
            alert("Student created successfully");
        } catch (error) {
            console.log(error);
            alert("Failed to create student");
        }
    };


    return (
        <div className="container">
            <nav className="navbar">
                <Link to={"/searchPage"}><button type="searchPage">Search</button></Link>
                <Popup trigger={<button type="logOut">Logout</button>} modal nested>
                    {_close => (
                        <div className='modal'>
                            <div type="logOutDescription">
                                Are you sure?
                            </div>
                            <Link to={"/"}>
                                <button type="logOutConfirm">
                                    Logout
                                </button>
                            </Link>
                            <button type="logOutCancel" onClick={() => { _close() }}>
                                Cancel
                            </button>
                        </div>
                    )}
                </Popup>
            </nav>

            <form onSubmit={handleSubmit}>
                <h1>Create New Student</h1>
                <table>
                    <tbody>
                        <tr>
                            <td type="header">Student ID:</td>
                            <td>
                                <input
                                    value={newStudent.student_id}
                                    onChange={(e) => handleChange(e, "student_id")}
                                    type="text"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td type="header">First Name:</td>
                            <td>
                                <input
                                    value={newStudent.first_name}
                                    onChange={(e) => handleChange(e, "first_name")}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Last Name:</td>
                            <td>
                                <input
                                    value={newStudent.last_name}
                                    onChange={(e) => handleChange(e, "last_name")}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Grad Year:</td>
                            <td>
                                <input
                                    value={newStudent.grad_year}
                                    onChange={(e) => handleChange(e, "grad_year")}
                                    required
                                    type="number"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Notes:</td>
                            <td>
                                <input
                                    value={newStudent.Notes}
                                    onChange={(e) => handleChange(e, "Notes")}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Otterbein Email:</td>
                            <td>
                                <input
                                    value={newStudent.otterbein_email}
                                    onChange={(e) => handleChange(e, "otterbein_email")}
                                    required
                                    type="email"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Phone Number:</td>
                            <td>
                                <input
                                    value={newStudent.Phone_Number}
                                    onChange={(e) => handleChange(e, "Phone_Number")}
                                    type="number"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Minor Code:</td>
                            <td>
                                <input
                                    value={newStudent.minor_code}
                                    onChange={(e) => handleChange(e, "minor_code")}
                                    type="number"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Major Code:</td>
                            <td>
                                <input
                                    value={newStudent.major_code}
                                    onChange={(e) => handleChange(e, "major_code")}
                                    type="text"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Street Address:</td>
                            <td>
                                <input
                                    value={newStudent.ST_ADDR}
                                    onChange={(e) => handleChange(e, "ST_ADDR")}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">City:</td>
                            <td>
                                <input
                                    value={newStudent.CITY}
                                    onChange={(e) => handleChange(e, "CITY")}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">State Code:</td>
                            <td>
                                <input
                                    value={newStudent.ST_CD}
                                    onChange={(e) => handleChange(e, "ST_CD")}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Zip Code:</td>
                            <td>
                                <input
                                    value={newStudent.ZIP_CD}
                                    onChange={(e) => handleChange(e, "ZIP_CD")}
                                    required
                                    type="number"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Country Code:</td>
                            <td>
                                <input
                                    value={newStudent.CTRY_CD}
                                    onChange={(e) => handleChange(e, "CTRY_CD")}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Email:</td>
                            <td>
                                <input
                                    value={newStudent.Email}
                                    onChange={(e) => handleChange(e, "Email")}
                                    type="email"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td type="header">Phone Number:</td>
                            <td>
                                <input
                                    value={newStudent.Phone_Number}
                                    onChange={(e) => handleChange(e, "Phone_Number")}
                                    type="number"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td type="header">Student ID:</td>
                            <td>
                                <input
                                    value={newStudent.student_id}
                                    onChange={(e) => handleChange(e, "student_id")}
                                    type="number"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <button type="submit">Create</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}

export default CreateStudentPage;
