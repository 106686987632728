import React, {useState} from 'react';
import { Link } from "react-router-dom";
import PasswordChecklist from 'react-password-checklist'; 
function NewPass (){
    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")   
 return ( <div>
       <div className="container">
 <nav className="navbar"></nav><form>
    <h3>Enter your new password</h3>
          <label>Enter your new password:</label>
          <input type="password" 
                 onChange={e => setPassword(e.target.value)}>
          </input>
          <label>Re-enter your new password:</label>
          <input type="password" 
                 onChange={e => setPasswordAgain(e.target.value)}>
          </input>

          <PasswordChecklist
              rules={["minLength","specialChar",
                      "number","capital","match"]}
              minLength={5}
              value={password}
              valueAgain={passwordAgain}
          />
           <Link to={"/newPassCon"}><button type="newPassButton">submit</button></Link>
      </form>
      </div>
      </div>
 )
}
  
export default NewPass;