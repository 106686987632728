import React from 'react';
import { Link } from "react-router-dom"; 
function Forgot (){
 return <div>
            <div className="container">
            <nav className="navbar"></nav>
            <h1 type="title">Forgotten Password</h1>
            <p type="description" className="description">Please enter your email and we will send your a link to reset your password.</p>
           
            <Link to={"/newPasswordSub"}><button type="submitButtonTypeTwo">submit</button></Link>
        </div>
        </div>
}
  
export default Forgot;