import React, { useState } from "react";
import logo from './logo.svg';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { useNavigate } from "react-router-dom";

function LoginPage({ onLogin }) { // Add onLogin prop here
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await Axios.post("/login", {
        username,
        password,
      });

      if (response.data.success) {
        onLogin(); // Call onLogin prop here
        navigate("/searchPage");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred. Please try again.");
    }
  };


  return (
    <div className="container">
      <nav className="navbar"></nav>
      <img src={logo} alt="Logo" />
      <h1 type="title">OtterFind</h1>

      <form onSubmit={handleLogin}>
        <input
          type="name"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link id="forgotDesign" to={"/forgot"}>
          Forgot Password
        </Link>
        <button type="submitButtonTypeOne">
          Login
        </button>
      </form>
    </div>
  );
}

export default LoginPage;
