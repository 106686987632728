import React from 'react';
import { Link } from "react-router-dom"; 
function NewPasswordSub (){
 return <div>
            <div className="container">
            <nav className="navbar"></nav>
            <h1 type="title">Email has been submitted!</h1>
            <text type="description">Please check your email for a link that will send you to a page to change your password. 
            If the link sent does not show in your inbox, check your junk inbox.</text>
            <Link to={"/login"}><button type="submitButtonTypeTwo">Return to the login</button></Link>
        </div>
        </div>
}
  
export default NewPasswordSub;