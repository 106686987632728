import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";  
import { createRoot } from 'react-dom/client';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
//import { getActiveElement } from '@testing-library/user-event/dist/utils';
import * as XLSX from 'xlsx';
import axios from 'axios'; // npm install react-axios


function SearchPage () {
  // eslint-disable-next-line no-unused-vars
  
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeFilter, setActiveFilter] = useState(null);
  const tableRef = React.useRef();
  const [editingStudentId, setEditingStudentId] = useState(null);
  const [editedStudent, setEditedStudent] = useState({});

  const handleChange = (e, fieldName) => {
    setEditedStudent({ ...editedStudent, [fieldName]: e.target.value });
  };

  const handleSave = (studentId) => {
    updateStudent(studentId, editedStudent);
    setEditingStudentId(null);
  };

  const handleEdit = (studentId, student) => {
    setEditingStudentId(studentId);
    setEditedStudent(student);
  };
  

  useEffect(() => {
    getAllStudents();
  }, []);

  const getAllStudents = async () => {
    try {
      //Before push uncomment this statement and comment the statement be
      //const response = await axios.get(`/students`);
      const response = await axios.get(`/students`);
      setStudents(response.data.rows);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExport = () => {
    const wb = XLSX.utils.table_to_book(tableRef.current, {sheet: "Students"});
    const wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'binary'});

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportFile = (filename, data) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
      link.download = filename;
      link.click();
    }

    exportFile("students_export.xlsx", s2ab(wbout));
  }

  const filteredStudents = students.filter((student) => {
    const fullName = `${student.first_name} ${student.last_name}`.toLowerCase();
    const searchString = searchQuery.toLowerCase();
    return fullName.includes(searchString) || student.grad_year.toString().includes(searchString);
  });

  document.addEventListener("DOMContentLoaded", function() {
    var filterToggle = document.getElementById("filter-toggle");
    if (filterToggle) {
      filterToggle.addEventListener("click", function() {
        var filterPanel = document.getElementById("filter-panel");
        if (filterPanel) {
          filterPanel.classList.toggle("hidden");
        }
      });
    }
  });
  
  const handleFilterChange = (e) => {
    setActiveFilter(e.target.value === "None" ? null : e.target.value);
  };

  const updateStudent = async (studentId, updatedStudent) => {
    try {
      await axios.put(`/students/${studentId}`, updatedStudent);
      //await axios.put(`/students/${studentId}`, updatedStudent);
      getAllStudents();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <nav className="navbar">
        <Link to={"/helpPage"}><button type="helpPage">Help</button></Link>
        <button type="export" onClick={handleExport}>Export</button>
        <Popup trigger= {<button type="logOut">Logout</button>} modal nested>
          {
            _close => ( 
                <div className='modal'> 
                  <div type="logOutDescription">Are you sure?</div>
                  <div type="logoutOptions">
                    <Link to={"/login"}><button type="logOutConfirm">Logout</button></Link>
                    <button type="logOutCancel" onClick={() => {_close()}}>Cancel</button>
                  </div>
                </div>
            )
          }
        </Popup>
      </nav>  
      <div className="searchContainer">
        <input
          className='searchInput'
          type="text"
          placeholder="Name, Graduation Status, Graduation Year..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <button
        id="filter-toggle"
        onClick={() => setActiveFilter(activeFilter ? null : "None")}
      >
        Filter
      </button>
      {activeFilter && (
        <div id="filter-panel" class="hidden">
          <div class="filters">
            <Link to={"/SearchPage"}><button type="helpPage">All Otterbein Students</button></Link>
          </div>
          <div class="filters">
            <Link to={"/prospectStudents"}><button type="helpPage">Filter by Prospect Students</button></Link>
          </div>
          <div class="filters">
            <Link to={"/currentStudents"}><button type="helpPage">Filter by Current Students</button></Link>
          </div>
          <div class="filters">
            <Link to={"/alumniStudents"}><button type="helpPage">Filter by Alumni Students</button></Link>
          </div>
        </div>
      )}
      <div className="filters">
        {students && students.length > 0 ? (
          <table ref={tableRef}>
            <table>
              <thead>
                <tr>
                  <th>Student ID</th>
                  <th>Prospect ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Otterbein Email</th>
                  <th>Graduation Year</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                  {filteredStudents.map((student) => (
                  <tr key={student.student_id}>
                    <td><Link to={`/resultPage/${student.student_id}`}>{student.student_id}</Link></td>
                   
                    <td>{student.prospect_id}</td>
                    {editingStudentId === student.student_id ? (
                      <>
                        <td>
                          <input
                            defaultValue={student.first_name}
                            onChange={(e) => handleChange(e, "first_name")}
                          />
                        </td>
                        <td>
                          <input
                            defaultValue={student.last_name}
                            onChange={(e) => handleChange(e, "last_name")}
                          />
                        </td>
                        <td>
                          <input
                            defaultValue={student.otterbein_email}
                            onChange={(e) => handleChange(e, "otterbein_email")}
                          />
                        </td>
                        <td>
                          <input
                            defaultValue={student.grad_year}
                            onChange={(e) => handleChange(e, "grad_year")}
                          />
                        </td>
                        <td>
                          <input
                            defaultValue={student.Notes}
                            onChange={(e) => handleChange(e, "Notes")}
                          />
                        </td>
                        <td>
                          <button onClick={() => handleSave(student.student_id)}>Save</button>
                          <button onClick={() => handleEdit(null)}>Cancel</button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{student.first_name}</td>
                        <td>{student.last_name}</td>
                        <td>{student.otterbein_email}</td>
                        <td>{student.grad_year}</td>
                        <td>{student.Notes.split(" ").slice(0, 5).join(" ")}</td>
                        <td>
                          <button onClick={() => handleEdit(student.student_id, student)}>
                            Edit
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </table>
        ) : (
          <div>No students found.</div>
        )}
      </div>
    </div>
  );
}     
export default SearchPage;