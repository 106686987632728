import React from 'react';
import { Link } from "react-router-dom"; 
function NewPassCon (){
 return <div>
            <div className="container">
            <nav className="navbar"></nav>
            <h1 type="title">New Password has been created!</h1>
            <text type="description">New password has been created return to the login with the new password.</text>
            <Link to={"/"}><button type="submitButtonTypeTwo">Return to the login</button></Link>
        </div>
        </div>
}
  
export default NewPassCon;