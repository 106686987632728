import './app.css';
import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  Navigate
} from 'react-router-dom';
import LoginPage from './component/loginPage';
import SearchPage from './component/searchPage';
import ResultPage from './component/resultPage';
import Forgot from './component/forgot';
import HelpPage from './component/helpPage';
import NewPasswordSub from './component/newPasswordSub';
import NewPass from './component/newPass';
import NewPassCon from './component/newPassConfirm';
import './app.css';
import AlumniStudents from './component/alumniStudents';
import CurrentStudents from './component/currentStudents';
import ProspectStudents from './component/prospectStudents';
import NewStudent from './component/newStudent';


function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };
  
  const requireAuth = (nextState, replace) => {
    if(!this.authenticated()) // pseudocode - SYNCHRONOUS function (cannot be async without extra callback parameter to this function) 
      replace('/login');
    }
    //Route path="/helpPage" element={<HelpPage />} />
  return (
    <Routes>
      <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
      <Route path="/searchPage" element={isLoggedIn ? <SearchPage /> : <Navigate to="/login" />} />
      <Route path="/resultPage/:student_id" element={isLoggedIn ? <ResultPage /> : <Navigate to="/login" />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/" element={<LoginPage onLogin={handleLogin} />} />
      <Route path="/newPasswordSub" element={<NewPasswordSub />} />
      <Route path="/newPass" element={<NewPass />} />
      <Route path="/newPassCon" element={<NewPassCon />} />
      <Route path="/prospectStudents" element={<ProspectStudents />} />
      <Route path="/currentStudents" element={<CurrentStudents />} />
      <Route path="/alumnistudents" element={<AlumniStudents />} />
      <Route path="/newStudent" element={<NewStudent />} />
    </Routes>
  );
}

export default App;
